<template>
  <div v-if="($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S') && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              >Modo professor</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Dashboard</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Dashboard 
                  <span id="txtDashboardNomePlataforma">docente</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="max-width: 1920px"
        >
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <section class="p-4">
                <div class="row">   
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              ALUNOS RESPONDIDOS
                            </div>
                            <div class="h1 mb-0 font-weight-bold text-success">
                              250
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-chalkboard-teacher fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              AULAS PARTICULARES
                            </div>
                            <div class="h1 mb-0 font-weight-bold text-success">
                              25
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-chalkboard-teacher fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              REVISÕES DE REDAÇÃO
                            </div>
                            <div class="h1 mb-0 font-weight-bold text-success">
                              1
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-chalkboard-teacher fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Atividades
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="line"
                          height="350"
                          :options="chartOptions1"
                          :series="series1"
                        />
                      </div>
                    </div> 
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Sua avaliação
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="area"
                          height="350"
                          :options="chartOptions2"
                          :series="series2"
                        />
                      </div>
                    </div>  
                  </div>               
                  <div class="col-sm-12 col-md-12 col-lg-6 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Horas por mês
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="bar"
                          height="350"
                          :options="chartOptions4"
                          :series="series4"
                        />
                      </div>
                    </div>                    
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Média de avaliação por turma
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="pie"
                          width="380"
                          :options="chartOptions3"
                          :series="series3"
                        />
                      </div>
                    </div>                       
                  </div>  
                  <div class="col-12 p-4">
                    <div class="card shadow h-100">     
                      <div class="card-header">
                        Últimas atividades
                      </div>               
                      <div class="card-body">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                Atividade
                              </th>
                              <th scope="col">
                                Aluno
                              </th>
                              <th scope="col">
                                Data
                              </th>
                              <th scope="col">
                                Avaliação
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Revisão de redação</td>
                              <td>João Silva</td>
                              <td>05/01/2021</td>
                              <td>
                                <span class="fa fa-star checked" />
                                <span class="fa fa-star checked" />
                                <span class="fa fa-star checked" />
                                <span class="fa fa-star" />
                                <span class="fa fa-star" />
                              </td>
                            </tr>                                       
                          </tbody>
                        </table>
                      </div>
                    </div>                    
                  </div>               
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos

export default {
  name: "HomeInternoProfessorDashboard",
  components: {
    VueApexCharts
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Chart 1
      series1: [{
              name: "Perguntas",
              data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            },
            {
              name: "Aulas particulares",
              data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
            },
            {
              name: 'Revisões de redação',
              data: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0]
            }
      ],
      chartOptions1: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5]
        },
        title: {
          text: 'Último ano',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: ['01 Jan', '01 Fev', '01 Mar', '01 Abr', '01 Mai', '01 Jun', '01 Jul', '01 Ago', '01 Set', '01 Out', '01 Nov', '01 Dez'],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val 
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val 
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },
      // Chart 2
      series2: [{
        name: "Avaliação",
        data: [90, 90, 95, 96, 94, 95, 93, 98, 98, 97, 95, 98]
      }],
      chartOptions2: {
            chart: {
              type: 'area',
              height: 350,
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            
            title: {
              text: 'Último ano',
              align: 'left'
            },
            subtitle: {
              text: '',
              align: 'left'
            },
            labels: ['01 Jan', '01 Feb', '01 Mar', '01 Apr', '01 May', '01 Jun', '01 Jul', '01 Aug', '01 Sep', '01 Oct', '01 Nov', '01 Dec'],//['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan', '10 Jan', '11 Jan', '12 Jan'],
            xaxis: {
              type: 'datetime',
            },
            yaxis: {
              opposite: true
            },
            legend: {
              horizontalAlign: 'left'
            }
      },
      series3: [90, 95, 98],
      chartOptions3: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['SEGM2', 'SEGT2', 'SEGT3'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series4: [{
        name: 'Horas',
        data: [120, 126, 100, 50, 60, 55, 68, 100, 115, 110, 86, 90]
      }],
      chartOptions4: {
        annotations: {
          points: [{
            x: 'Fevereiro',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: 'Maior número',
            }
          }]
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'  
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Servings',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
      },
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    
  },
};
</script>

<style></style>

import { render, staticRenderFns } from "./HomeInternoProfessorDashboard.vue?vue&type=template&id=562f5f74"
import script from "./HomeInternoProfessorDashboard.vue?vue&type=script&lang=js"
export * from "./HomeInternoProfessorDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports